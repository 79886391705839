export const dinein = {
  en: {
    addProvider: "Add Restaurant",
    deleteCouponWarning:
      "Are you sure you want to permanently delete this coupon?",
    coupons: "Coupons",
    addCoupon: "Add Coupon",
    active: "Active",
    upcoming: "Upcoming",
    expired: "Expired",
    couponName: "Coupon Name",
    couponCode: "Code",
    description: "Description",
    couponStartDate: "Start Date",
    couponEndDate: "End Date",
    couponUses: "Uses",
    startsImmediately: "Starts immediately",
    neverEnds: "Never ends",
    unlimited: "Unlimited",
    titleWarning: "You have to add the Title first!",
    validEmailWarning: "You have to add a valid email",
    valuePercentageWarning: "You have to add at least a Value or a Percentage",
    validDatesWarning: "You have to add a valid range of dates!",
    differentNameWarning:
      "A coupon with this name already exists. Type a different name",
    edit: "Edit",
    add: "Add",
    cancel: "Cancel",
    save: "Save",
    value: "VALUE",
    or: "OR",
    valueOrPercentage: "You have to add either the Value or the Percentage",
    PERCENTAGE: "PERCENTAGE",
    maxValue: "MAX VALUE",
    minOrder: "MIN ORDER",
    count: "COUNT",
    email: "EMAIL",
    restrictions: "Restrictions",
    group: "Group",
    venue: "Venue",
    selectAllProviders: "Select all the restaurants of this group",
    item: "Item",
    select: "Select",
    venueFirst: "Choose a venue first",
    noMoreData: "No more data",
    selectGroupFirst: "You have to select a group first",
    owner: "Owner",
    hcn: "HCN",
    dragDrop: "Drag 'n' drop a file here, or click to select it.",
    addPhotoDesc: "Add photo. Uploaded file should not exceed ",
    upload: "Upload",
    cloneFiltersWarning:
      "You are about to Clone this Filter. You have to change its name & acronym!",
    addFilter: "Add Filter",
    importFilter: "Import Filter",
    filterName: "Filter Name",
    acronym: "Acronym",
    filters: "Filters",
    loginError:
      "The combination of username / password provided was wrong. Please try again.",
    username: "Username",
    password: "Password",
    login: "Log In",
    deleteSectionWarning: "Are you sure you want to delete this section?",
    addNewCategory: "Add new Category",
    editCategory: "Edit Category",
    categoryName: "Category name",
    iconList: "Icon List",
    searchbar: "Searchbar",
    rootCategoryUpdatedSuccess: "Menu is updated successfully",
    categories: "Categories",
    newCategory: "New Category",
    newCategoriesItems: "New Category Items",
    suggestionItems: "Suggestion Items",
    changedValue: "Changed value",
    selectedAllItemsWarning:
      "You have selected all the items! There are no items left unselected!",
    selectItems: "Select Items",
    rootCategoryItems: "Menu Items",
    price: "Price",
    weSuggest: "We suggest",
    rootCategoryItem: "Menu Item",
    filter: "Filter",
    availability: "Availability",
    updateItem: "Update item",
    saveSection: "Save section",
    addNewItem: "Add New Item",
    editItem: "Edit Item",
    itemName: "Item Name",
    itemDescription: "Item Description",
    itemPrice: "Item Price",
    defaultImage: "Default Image",
    mainImage: "Main Image",
    thumbnailImage: "Thumbnail Image",
    isAlcohol: "Is Alcohol?",
    extraTaxes: "Extra Taxes",
    taxes: "Taxes",
    addUpsells: "Add Upsells",
    logOut: "Log out",
    deleteFilterWarning:
      "Are you sure you want to permanently delete this filter?",
    selectProviderFilters: "Select restaurant filters",
    selectAll: "Select all",
    clearAll: "Clear all",
    required: "Required",
    optional: "Optional",
    selectOne: "Customer can select only one",
    selectMultiple: "Customer can select multiple",
    max: "Max",
    min: "Min",
    cloneModifierWarning:
      "You are about to Clone this Modifier. First, you have to change its name",
    addModifier: "Add Modifier",
    minMaxError: "The Min number should be lower than the Max number",
    minMaxValidError: "The Min and Max numbers should be valid numbers!",
    modifierName: "Modifier Name",
    displayForGuests: "Display Title for Guests",
    addAnOption: "Add an option",
    modifierOptions: "Modifier Options",
    selectProviderModifier: "Select restaurant modifier",
    import: "Import",
    importModifier: "Import Modifier",
    deleteModifierWarning:
      "Are you sure you want to permanently delete this modifier?",
    noHotelsLeftWarning: "There are no hotels left to create a new group!",
    newRowGroupWarning:
      "You have to add at least 1 restaurant before adding a new row",
    addOneProviderWarning:
      "You have to add at least 1 restaurant before adding saving this Group Restaurant",
    addTwoProvidersInRow:
      "You have to add 2 Restaurants in every Row, except the last Row, before saving this Group Restaurant",
    addAllFields: "You have to add all mandatory fields",
    deleteProviderFromRow:
      "Are you sure you want to permanently delete this restaurant from row",
    deleteRow: "Are you sure you want to permanently delete row",
    title: "Title",
    dropzoneText:
      "Please drop a file here or click to select it from your computer. ( * )",
    selectHotel: "Select a hotel",
    addRow: "Add Row",
    chooseProvider: "Choose Restaurant",
    deleteGroupWarning:
      "Are you sure you want to permanently delete this group?",
    fillAllRequestedDetails:
      "Please fill in all the requested details below to process an order on behalf of a customer",
    addGroup: "Add group",
    groupName: "Group Name",
    groupId: "Group Name",
    hotel: "Hotel",
    NumberOfProviders: "No. of Restaurant",
    addNewExtraTax: "Add new extra tax",
    name: "Name",
    percentage: "Percentage",
    multiply: "Multiply",
    addNewFee: "Add new fee",
    costPercentage: "Cost/Percentage",
    attribute: "Attribute",
    addFee: "Add a fee",
    providerName: "Restaurant Name",
    providerType: "Restaurant Type",
    american: "American",
    asian: "Asian",
    canadian: "Canadian",
    chinese: "Chinese",
    continental: "Continental",
    cuban: "Cuban",
    french: "French",
    german: "German",
    greek: "Greek",
    indian: "Indian",
    indonesian: "Indonesian",
    italian: "Italian",
    japanese: "Japanese",
    korean: "Korean",
    lebanese: "Lebanese",
    liquorStore: "Liquor Store",
    malaysian: "Malaysian",
    mediterranean: "Mediterranean",
    mexican: "Mexican",
    moroccan: "Moroccan",
    nigerian: "Nigerian",
    pub: "Pub",
    russian: "Russian",
    singaporean: "Singaporean",
    southAfrican: "South African",
    spanish: "Spanish",
    steakhouse: "Steakhouse",
    thai: "Thai",
    tibetan: "Tibetan",
    turkish: "Turkish",
    vietnamese: "Vietnamese",
    teaser: "Teaser",
    timezone: "Timezone",
    deliveryTime: "Delivery Time (min)",
    tips: "Tips (%)",
    providerInfo: "Restaurant Info",
    placeSettings: "Place Settings",
    DEFAULTIMAGE: "DEFAULT IMAGE (ITEM)",
    feesTaxes: "Fees & Taxes",
    fees: "Fees",
    addNewTax: "Add new tax",
    appliedTo: "Applied To",
    addTax: "Add a tax",
    changeBeforeSave:
      "You need to perform at least one change before you save!",
    providerInfoSaveWarning:
      "You have to fill all the appropriate data: Name, Type, Teaser, Timezone, Delivery Time, Info, at least 1 image, and at least 1 Fee",
    providerInfoSaveWarningWithEmail:
      "You have to fill all the appropriate data: Name, Type, Teaser, Email, Timezone, Delivery Time, Info, at least 1 image, and at least 1 Fee",
    provider: "restaurant",
    welcomeProvider: "Welcome to your restaurant",
    welcomeProviderText:
      "Now you can start building your restaurant settings and category",
    deleteProviderWarning:
      "Are you sure you want to permanently delete this restaurant?",
    configure: "Configure",
    providers: "Restaurants",
    deleteCategoryWarning:
      "Are you sure you want to permanently delete this category?",
    rootCategory: "Menu",
    items: "Items",
    modifiers: "Modifiers",
    PROVIDER: "RESTAURANT",
    providerGroups: "Restaurant Groups",
    TIMESETTINGS: "TIME SETTINGS",
    tfSaved: "The timeframes have been saved successfully!",
    timeSettings: "Time Settings",
    addTimeFrame: "Add Time Frame",
    defaultProviderSettings: "Default Restaurant Settings",
    specialEvents: "Special Events",
    openStart: "Open/Start",
    closeEnd: "Close/End",
    days: "Days",
    M: "M",
    T: "T",
    W: "W",
    F: "F",
    S: "S",
    currency: "$",
    startDate: "START DATE",
    endDate: "END DATE",
    welcomeText:
      "Welcome to the HCN administration panel. Please start by adding the Restaurants.",
    fName: "First Name: ",
    lName: "Last Name: ",
    roomNumber: "Room Number: ",
    ok: "OK",
    signOutWarning: "WARNING!\nYou will be signed out in 2 minutes!",
    addFeaturedSection: "Add Featured Section",
    addToCategory: "Add to Category",
    deleteCategoryItemWarning:
      "Are you sure you want to permanently delete this item from this category?",
    deleteFeaturedSectionWarning:
      "Are you sure you want to permanently delete this item from the current featured section?",
    updateAvailabilityFailedWarning: "Failed to update item availability",
    addNamePriceImg:
      "You have to add at least a name, a price and a default image before you save it",
    deleteItemWarning: "Are you sure you want to delete this item?",
    searchItem: "search an item",
    deleteRootCategoryWarning:
      "Are you sure you want to permanently delete this menu?",
    delete: "Delete",
    providerComm: "Communication Type",
    webSockets: "Web Sockets",
    pushNotifications: "Push Notifications",
    emails: "Emails",
    synergy: "Synergy",
    synergyTaskCode: "Synergy Task Code",
    dispatcher: "Dispatcher",
    agent: "Agent",
    paymentRequired: "Payment Required",
    selfDelivered: "Self Delivered",
    showAllOrderDetails: "Show all order details",
    showPhoneColumn: "Show phone column",
    showMailColumn: "Show mail column",
    showCouponColumn: "Show coupon column",
  },
};
